import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { InfoContext } from '../context';
import Field from '../custom/Field';
import CheckboxField from '../custom/CheckboxField';
import RadioGroup from '../custom/RadioGroup';
import { generatePdf } from '../../js/api-service';
import Spinner from '../Spinner';
import LyHealthDeclaration from './LyHealthDeclaration';
import LyHealthSubmit from './LyHealthSubmit';

export default function LyHealthForm() {
    const { name, personNumber, parterList, lyHealthFormData, bankIdName, setCurrentCase, session, lyHealthQuestions } = useContext(InfoContext);
    const [checkApprove, setcheckApprove] = useState(false);
    const [validCheck, setValidCheck] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [declaration, setDeclaration] = useState(false);
    const [stage, setStage] = useState(1);
    const [radioRequired, setRadioRequired] = useState("pending");
    const [lyQuestions, setLyQuestions] = useState();
    const LyFormRef = useRef();
    let { id } = useParams();
    let parterItem = parterList?.find(p => p.claimNumber === id);
    let lyFormData = lyHealthFormData[id] ?? {};
    let lyQuestionsLabel = lyHealthQuestions[id] ?? {};
    const navigate = useNavigate();
    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        // if (bankIdName === "") { navigate("/") }
    }, [bankIdName, stage])

    useEffect(() => {
        setCurrentCase(id);
    }, [])

    const handleNext = (event) => {
        event.preventDefault();
        let name, lyLabel, preLabel = "", lyValue, lyTmpQuestions = [];
        if (stage === 1) {
            if (lyFormData["lyhelse-declarationApproved"]) {
                setcheckApprove(false);
                setDeclaration(true);
                setStage(2);
            } else {
                setcheckApprove(true);
            }
        } else {
            setDeclaration(false);
            setValidCheck(true);
            setRadioRequired(true);
            if (!LyFormRef.current.checkValidity()) {
                LyFormRef.current.querySelector(':invalid').focus();
                return;
            } else {
                const indentBlocks = LyFormRef.current.querySelectorAll('div.indentBlock input, div.indentBlock textarea')
                Array.from(indentBlocks).map((formItem) => {
                    formItem.classList.add('indent-item')
                })
                const formItems = LyFormRef.current.querySelectorAll('input, textarea')
                Array.from(formItems).map((formItem, index) => {
                    if (formItem.classList.contains('indent-item')) {
                        preLabel = '--'
                    } else {
                        preLabel = ''
                    }
                    name = formItem.name ?? "";
                    if (formItem.type.toLowerCase() === "radio") {
                        lyLabel = preLabel + lyQuestionsLabel[name];
                    } else {
                        lyLabel = `${preLabel}${lyQuestionsLabel[name]}---${index}`;
                    }
                    lyValue = lyFormData[name];
                    if (!lyValue || lyValue?.toString()?.trim() === "") { return }
                    lyTmpQuestions = { ...lyTmpQuestions, [lyLabel]: lyValue };
                })
                let questionsAnswers = Object.keys(lyTmpQuestions).map((key) => [key.split('---')[0], lyTmpQuestions[key]])
                setLyQuestions(questionsAnswers);
                setStage(3);
            }
        }
    }

    const lyHealthSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true);
        const pdfInput = {
            name: name,
            personalNumber: personNumber ?? "",
            clientName: "LY HELSE",
            claimNumber: id,
            systemId: parterItem.systemID,
            adjusterId: parterItem.adjusterID,
            templateName: "lyhealth",
            DocumentName: "LY-HELSE",
            DocumentDescription: "Ly helse signering",
            QuestionsAndAnswers: lyQuestions
        }

        const pdf = await generatePdf(session, pdfInput);
        if (pdf.redirectUri !== "" && pdf.redirectUri !== null) {
            window.location.href = pdf.redirectUri;
        }
    }

    return (
        <div className='LyFullmaktForm'>
            {submitted && <Spinner title="Vennligst vent..." />}
            {!declaration && stage === 1 ? <>
                <LyHealthDeclaration />
                <br />
                <CheckboxField item={{ name: "lyhelse-declarationApproved", label: 'Jeg bekrefter at jeg har lest "Orientering om helseerklæring"', value: lyFormData["lyhelse-declarationApproved"], isValidCheck: checkApprove, required: true }} />
            </> :
                <form ref={LyFormRef} id='LyHealthForm' name='LyHealthForm' onSubmit={(event) => { lyHealthSubmit(event) }} noValidate >
                    <div className={`${stage !== 2 ? "no-display" : ""}`}>
                        <h2 className='medium-header'>LY FORSIKRING ASA HELSE SKJEMA</h2>
                        {/* <h5 className="small-header">Personal informasjon</h5> */}
                        <div className='lyhelse-personal-info'>
                            <Field item={{ name: "lyhelse-navn", type: "text", required: true, isValidCheck: validCheck, label: "Arbeidstakers fullstendige navn", value: lyFormData["lyhelse-navn"] }} />
                            <Field item={{ name: "lyhelse-personNummer", type: "text", required: true, isValidCheck: validCheck, label: "Arbeidstakers fødselsnummer (11 siffer)", value: lyFormData["lyhelse-personNummer"], pattern: "[0-9]{11}$" }} />
                            <Field item={{ name: "lyhelse-adress", type: "text", required: true, isValidCheck: validCheck, label: "Adresse", value: lyFormData["lyhelse-adress"] }} />
                            <Field item={{ name: "lyhelse-postSted", type: "text", required: true, isValidCheck: validCheck, label: "Poststed", value: lyFormData["lyhelse-postSted"] }} />
                            <Field item={{ name: "lyhelse-postNumber", type: "text", required: true, isValidCheck: validCheck, label: "Postnummer", value: lyFormData["lyhelse-postNumber"], pattern: "[0-9]{4}$" }} />
                            <Field item={{ name: "lyhelse-partnerName", type: "text", isValidCheck: validCheck, label: "Forsikrede ektefelle/samboers fullstendige navn", value: lyFormData["lyhelse-partnerName"] }} />
                            <Field item={{ name: "lyhelse-partenerPersonNumber", type: "text", isValidCheck: validCheck, label: "Ektefelles/samboers fødselsnummer (11 siffer)", value: lyFormData["lyhelse-partenerPersonNumber"], pattern: "[0-9]{11}$" }} />
                            <Field item={{ name: "lyhelse-mobil", type: "text", isValidCheck: validCheck, label: "Mobilnummer", value: lyFormData["lyhelse-mobil"] }} />
                            <Field item={{ name: "lyhelse-epost", type: "email", required: true, isValidCheck: validCheck, label: "Skriv inn gyldig e-post adresse", value: lyFormData["lyhelse-epost"] }} />
                            <Field item={{ name: "lyhelse-work", type: "text", isValidCheck: validCheck, label: "Stilling/yrke (hva består arbeidet av?)", value: lyFormData["lyhelse-work"], required: true }} />
                            <Field item={{ name: "lyhelse-employyeName", type: "text", isValidCheck: validCheck, label: "Arbeidsgivers navn/bedrift", value: lyFormData["lyhelse-employyeName"], required: true }} />
                        </div>

                        <h5 className="small-header">Helse status</h5>
                        <Field item={{ name: "lyhelse-height", type: "text", placeholder: "Høyde", isValidCheck: validCheck, label: "Hva er din nåværende høyde? (oppgis i cm)", value: lyFormData["lyhelse-height"], required: true }} />
                        <Field item={{ name: "lyhelse-weight", type: "text", placeholder: "Vekt", isValidCheck: validCheck, label: "Hva er din nåværende vekt? (oppgis i kg)", value: lyFormData["lyhelse-weight"], required: true }} />

                        <RadioGroup item={{ value: lyFormData["lyhelse-smokking"], name: "lyhelse-smokking", label: "Røyker du daglig, eller har du røkt daglig i løpet av de siste 2 år?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-smokking"] === "Ja" && <div id='lyhelse-smokking-block' className='indentBlock' >
                            <Field item={{ name: "lyhelse-howOftenSmoke", placeholder: "Pr. dag", isValidCheck: validCheck, label: "Hvor mye røyker du?", value: lyFormData["lyhelse-howOftenSmoke"], required: true }} />
                            <Field item={{ name: "lyhelse-quittingTime", isValidCheck: validCheck, label: "Hvis du har sluttet, når sluttet du? (oppgi måned og år)", value: lyFormData["lyhelse-quittingTime"], required: true }} />
                        </div>
                        }

                        <Field item={{ name: "lyhelse-legeNavnAdresse", placeholder: "Navn og adresse", isValidCheck: validCheck, label: "Oppgi navn og adresse på fastlege og legesenter", value: lyFormData["lyhelse-legeNavnAdresse"], required: true }} />

                        <RadioGroup item={{ value: lyFormData["lyhelse-sickness"], name: "lyhelse-sickness", label: "Har du noen form for sykdom, skade, medfødt lidelse eller funksjonssvikt (fysisk eller psykisk)?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-sickness"] === "Ja" && <div id='lyhelse-sickness-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-sicknessDescription", isValidCheck: validCheck, label: "Oppgi hvilken sykdom, skade, lidelse eller funksjonssvikt", value: lyFormData["lyhelse-sicknessDescription"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-sicknessSituation", isValidCheck: validCheck, label: "Oppgi situasjonen i dag", value: lyFormData["lyhelse-sicknessDescription"], required: true }} isTextarea={true} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-psychology"], name: "lyhelse-psychology", label: "Har du i løpet av siste 5 år blitt undersøkt eller behandlet av lege, psykolog, fysioterapeut, kiropraktor eller liknende? (Oppgi alle dersom du har hatt flere undersøkelser, operasjoner eller behandlinger)", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-psychology"] === "Ja" && <div id='lyhelse-psychology-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-psychologyName", isValidCheck: validCheck, label: "Av hvem? (Navn og adresse)", value: lyFormData["lyhelse-psychologyName"], required: true }} />
                            <Field item={{ name: "lyhelse-psychologyWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-psychologyWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-psychologyDiagnose", isValidCheck: validCheck, label: "Hvorfor/Diagnose?", value: lyFormData["lyhelse-psychologyDiagnose"], required: true }} />
                            <Field item={{ name: "lyhelse-psychologyResult", isValidCheck: validCheck, label: "Hva var resultatet?", value: lyFormData["lyhelse-psychologyResult"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-sicknessHistory"], name: "lyhelse-sicknessHistory", label: "Har du i løpet av siste 10 år blitt undersøkt eller behandlet ved sykehus, etc", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-sicknessHistory"] === "Ja" && <div id='lyhelse-sicknessHistory-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-sicknessHistoryName", isValidCheck: validCheck, label: "Av hvem? (Navn og adresse)", value: lyFormData["lyhelse-sicknessHistoryName"], required: true }} />
                            <Field item={{ name: "lyhelse-sicknessHistoryWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-sicknessHistoryWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-sicknessHistoryDiagnose", isValidCheck: validCheck, label: "Hvorfor/Diagnose?", value: lyFormData["lyhelse-sicknessHistoryDiagnose"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-sicknessHistoryResult", isValidCheck: validCheck, label: "Hva var resultatet?", value: lyFormData["lyhelse-sicknessHistoryResult"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-medicine"], name: "lyhelse-medicine", label: "Bruker du, eller har du i løpet av de siste 5 år brukt reseptbelagte medisiner regelmessig eller i perioder?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-medicine"] === "Ja" && <div id='lyhelse-medicine-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-medicineNameHvilke", isValidCheck: validCheck, label: "Hvilke medisiner?", value: lyFormData["lyhelse-medicineNameHvilke"], required: true }} />
                            <Field item={{ name: "lyhelse-medicineNameHvorfor", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-medicineNameHvorfor"], required: true }} isTextarea={true} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-sicknLeaveTwoTimes"], name: "lyhelse-sicknLeaveTwoTimes", label: "Har du i løpet av siste 5 år vært sykmeldt i mer enn 2 uker sammenhengende? (Oppgi alle dersom du har hatt flere sykemeldingsperioder)", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-sicknLeaveTwoTimes"] === "Ja" && <div id='lyhelse-sicknLeaveTwoTimes-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-sicknLeaveTwoTimesWhy", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-sicknLeaveTwoTimesWhy"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-sicknLeaveTwoTimesWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-sicknLeaveTwoTimesWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-sicknLeaveTwoTimesHowLong", isValidCheck: validCheck, label: "Hvor lenge?", value: lyFormData["lyhelse-sicknLeaveTwoTimesHowLong"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-planTreatment"], name: "lyhelse-planTreatment", label: "Er det planlagt eller anbefalt noen form for undersøkelse, behandling eller operasjon, eller venter du på svar på allerede gjennomført undersøkelse, behandling eller operasjon?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-planTreatment"] === "Ja" && <div id='lyhelse-planTreatment-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-planTreatmentWhy", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-planTreatmentWhy"], required: true }} />
                            <Field item={{ name: "lyhelse-planTreatmentWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-planTreatmentWhen"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-medicineLasttwelveMonth"], name: "lyhelse-medicineLasttwelveMonth", label: "Har du i løpet av de siste 12 måneder tatt noen medisinske prøver eller undersøkelser (blodprøver, røntgenbilder, ultralyd eller andre undersøkelser)?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-medicineLasttwelveMonth"] === "Ja" && <div id='lyhelse-medicineLasttwelveMonth'>
                            <Field item={{ name: "lyhelse-medicineLasttwelveMonthWhy", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-medicineLasttwelveMonthWhy"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-medicineLasttwelveMonthResult", isValidCheck: validCheck, label: "Hva var resultatet?", value: lyFormData["lyhelse-medicineLasttwelveMonthResult"], required: true }} isTextarea={true} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-tenYearPersonalInsurance"], name: "lyhelse-tenYearPersonalInsurance", label: "Har du i løpet av de siste 10 år søkt om personforsikring som har medført særvilkår (dvs. tilleggspremie, unntak eller avslag) på grunn av din helse?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-tenYearPersonalInsurance"] === "Ja" && <div id='lyhelse-tenYearPersonalInsurance-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-tenYearPersonalInsuranceWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-tenYearPersonalInsuranceWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-tenYearPersonalInsuranceWhy", isValidCheck: validCheck, label: "Hvilke forsikringsselskap?", value: lyFormData["lyhelse-tenYearPersonalInsuranceWhy"], required: true }} />
                            <Field item={{ name: "lyhelse-tenYearPersonalInsuranceReason", isValidCheck: validCheck, label: "Hva var årsaken til særvilkåret?", value: lyFormData["lyhelse-tenYearPersonalInsuranceReason"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-depresionLastFiveYear"], name: "lyhelse-depresionLastFiveYear", label: "Har du i løpet av siste 5 år hatt angst, depresjon, utmattelse/utbrenthet, ADHD, Asperger syndrom, eller andre psykiske plager?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-depresionLastFiveYear"] === "Ja" && <div id='lyhelse-depresionLastFiveYear-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-depresionLastFiveYearWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-depresionLastFiveYearWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-depresionLastFiveYearDescription", isValidCheck: validCheck, label: "Beskriv problemet", value: lyFormData["lyhelse-depresionLastFiveYearDescription"], required: true }} isTextarea={true} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-drinkingAlcohol"], name: "lyhelse-drinkingAlcohol", label: "Drikker du alkohol?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-drinkingAlcohol"] === "Ja" && <div id='lyhelse-drinkingAlcohol-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-drinkingAlcoholDescription", isValidCheck: validCheck, label: "Oppgi antall enheter pr uke (én alkoholenhet tilsvarer én flaske øl eller sider/«rusbrus» (0,33 l), ett glass vin (1,5 dl) eller én drink brennevin (4 cl))", value: lyFormData["lyhelse-drinkingAlcoholDescription"], required: true }} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-overUseAlchohol"], name: "lyhelse-overUseAlchohol", label: "Har du hatt overforbruk av alkohol, mottatt behandling for dette, eller blitt anbefalt behandling for dette i løpet av de siste 10 år?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-overUseAlchohol"] === "Ja" && <div id='lyhelse-overUseAlchohol-block' className='indentBlock'> <Field item={{ name: "lyhelse-overUseAlchoholTime", isValidCheck: validCheck, label: "Oppgi tidsperiode (fra-til)", value: lyFormData["lyhelse-overUseAlchoholTime"], required: true }} /></div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-useNarcotic"], name: "lyhelse-useNarcotic", label: "Bruker du eller har du i løpet av de siste 10 år brukt narkotika, rusmidler eller andre beroligende eller stimulerende midler, eller hatt overforbruk av medisiner?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-useNarcotic"] === "Ja" && <div id='lyhelse-useNarcotic-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-useNarcoticType", isValidCheck: validCheck, label: "Hvilke midler?", value: lyFormData["lyhelse-useNarcoticType"], required: true }} />
                            <Field item={{ name: "lyhelse-useNarcoticWhy", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-useNarcoticWhy"], required: true }} />
                            <Field item={{ name: "lyhelse-useNarcoticTime", isValidCheck: validCheck, label: "Tidsrom (fra/til)", value: lyFormData["lyhelse-useNarcoticTime"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-hivSickness"], name: "lyhelse-hivSickness", label: "Har du fått påvist HIV eller AIDS?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />

                        <RadioGroup item={{ value: lyFormData["lyhelse-applyPermision"], name: "lyhelse-applyPermision", label: "Har du i løpet av de siste 10 år søkt attføring/omskolering/arbeidsavklaringspenger/yrkesskadeerstatning eller andre trygdeytelser fra folketrygden?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-applyPermision"] === "Ja" && <div id='lyhelse-applyPermision-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-applyPermisionWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", value: lyFormData["lyhelse-applyPermisionWhen"], required: true }} />
                            <Field item={{ name: "lyhelse-applyPermisionWhy", isValidCheck: validCheck, label: "Hvorfor?", value: lyFormData["lyhelse-applyPermisionWhy"], required: true }} />
                            <Field item={{ name: "lyhelse-applyPermisionResult", isValidCheck: validCheck, label: "Utfallet av søknaden", value: lyFormData["lyhelse-applyPermisionResult"], required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-disabilityPayment"], name: "lyhelse-disabilityPayment", label: "Har du i løpet av de siste 10 år søkt om utbetaling av uføreytelse (betalingsfritak, uførerente, uførekapital, ykesskadeerstatning) fra forsikringsselskap eller pensjonskasse?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-disabilityPayment"] === "Ja" && <div id='lyhelse-disabilityPayment-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-lyhelse-disabilityPaymentWhen", isValidCheck: validCheck, label: "Når? (oppgi måned og år)", required: true }} />
                            <Field item={{ name: "lyhelse-disabilityPaymentReason", isValidCheck: validCheck, label: "Bakenforliggende helsemessig årsak", required: true }} />
                        </div>
                        }

                        <RadioGroup item={{ value: lyFormData["lyhelse-reducesWorkAbility"], name: "lyhelse-reducesWorkAbility", label: "Har du for tiden redusert arbeidsevne av helsemessige årsaker? (Med dette menes om du er sykemeldt, mottar arbeidsavklaringspenger, er arbeidsufør, får tilrettelegging eller mottar annen ytelse fra NAV grunnet helsemessige forhold", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-reducesWorkAbility"] === "Ja" && <div id='lyhelse-reducesWorkAbility-block' className='indentBlock'><Field item={{ name: "lyhelse-reducesWorkAbilityDescription", isValidCheck: validCheck, label: "Beskriv problemet", value: lyFormData["lyhelse-reducesWorkAbilityDescription"], required: true }} isTextarea={true} /></div>}

                        {/* <h6 id='lyhelse-lastFiveYearProblemQuestion' className='tiny-header'>Har du i løpet av de siste 5 år hatt vondt i/problemer med:</h6> */}
                        <RadioGroup item={{ value: lyFormData["lyhelse-lastFiveYearProblemQuestion"], name: "lyhelse-lastFiveYearProblemQuestion", label: "Har du i løpet av de siste 5 år hatt vondt i/problemer med rygg, nakke, skulder, arm, hofte/bekken, kne, ankel, fot, diffuse smerter eller andre plager i muskler, sener eller ledd?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-lastFiveYearProblemQuestion"] === "Ja" && <div id='lyhelse-lastFiveYearProblemQuestion-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-lastFiveYearProblemDescription", isValidCheck: validCheck, label: "Beskriv problemet/plagene", value: lyFormData["lyhelse-lastFiveYearProblemDescription"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-lastFiveYearProblemHowLong", isValidCheck: validCheck, label: "Hvor lenge varte plagene?", value: lyFormData["lyhelse-lastFiveYearProblemHowLong"], required: true }} />
                            <Field item={{ name: "lyhelse-lastFiveYearProblemLastTime", isValidCheck: validCheck, label: "Når hadde du sist plager? (måned/år)", value: lyFormData["lyhelse-lastFiveYearProblemLastTime"], required: true }} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-hearingProblem"], name: "lyhelse-hearingProblem", label: "Har du nedsatt hørsel/øresus nå?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-hearingProblem"] === "Ja" && <div id='lyhelse-hearingProblem-block' className='indentBlock'><Field item={{ name: "lyhelse-hearingProblemDescription", isValidCheck: validCheck, label: "Oppgi navn og adresse til lege som har opplysninger om dette", value: lyFormData["lyhelse-hearingProblemDescription"], required: true }} isTextarea={true} /></div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-hearingProblemLastTenYear"], name: "lyhelse-hearingProblemLastTenYear", label: "Har du eller har du hatt nedsatt hørsel, øresus eller øresykdom, skade eller lidelse i løpet av de siste 10 år?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-hearingProblemLastTenYear"] === "Ja" && <div id='lyhelse-hearingProblemLastTenYear-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-hearingProblemLastTenYearHowLong", isValidCheck: validCheck, label: "Hvor lenge har du hatt nedsatt hørsel/øresus (tidsrom fra/til)?", value: lyFormData["lyhelse-hearingProblemLastTenYearHowLong"], required: true }} />
                            <Field item={{ name: "lyhelse-hearingProblemLastTenYearDescription", isValidCheck: validCheck, label: "Hva er årsak (diagnose)?", value: lyFormData["lyhelse-hearingProblemLastTenYearDescription"], required: true }} isTextarea={true} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-visualProblem"], name: "lyhelse-visualProblem", label: "Har du nedsatt syn?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-visualProblem"] === "Ja" && <div id='lyhelse-visualProblem-block' className='indentBlock'><Field item={{ name: "lyhelse-visualProblemDescription", isValidCheck: validCheck, label: "Oppgi synsstyrke/type (+/-)", value: lyFormData["lyhelse-visualProblemDescription"], required: true }} isTextarea={true} /></div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-visualProblemLastTenYear"], name: "lyhelse-visualProblemLastTenYear", label: "Har du eller har du hatt øyesykdom, skade eller lidelse i løpet av de siste 10 år?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-visualProblemLastTenYear"] === "Ja" && <div id='lyhelse-visualProblemLastTenYear-block' className='indentBlock'>
                            <Field item={{ name: "lyhelse-visualProblemLastTenYearDiagnose", isValidCheck: validCheck, label: "Oppgi diagnose/plager", value: lyFormData["lyhelse-visualProblemLastTenYearDiagnose"], required: true }} isTextarea={true} />
                            <Field item={{ name: "lyhelse-visualProblemLastTenYearWhen", isValidCheck: validCheck, label: "Når hadde du sist symptomer/plager? (måned/år)", value: lyFormData["lyhelse-visualProblemLastTenYearWhen"], required: true }} />
                        </div>}

                        <RadioGroup item={{ value: lyFormData["lyhelse-otherInformation"], name: "lyhelse-otherInformation", label: "Det er av avgjørende betydning for gyldigheten av forsikringen, at selskapets risikovurdering bygger på korrekte og fullstendige opplysninger. Er det andre opplysninger om din helse som ikke har blitt oppgitt i spørsmålene over, eller har du tilleggsopplysninger?", required: radioRequired, values: ["Ja", "Nei"], labels: ["Ja", "Nei"] }} />
                        {lyFormData["lyhelse-otherInformation"] === "Ja" && <div id='lyhelse-otherInformation-block' className='indentBlock'><Field item={{ name: "lyhelse-otherInformationDescription", isValidCheck: validCheck, label: "Oppgi opplysninger", value: lyFormData["lyhelse-otherInformationDescription"], required: true }} isTextarea={true} /></div>}

                    </div>
                    {stage === 3 && <>
                        <LyHealthSubmit lyQuestions={lyQuestions} />
                        <button type='submit' className='submit-button margin-top__large padding-small'>Send inn</button>
                    </>
                    }
                </form >
            }
            {stage !== 3 && <button className='submit-button margin-top__large padding-small' onClick={event => handleNext(event)}>Neste</button>}
            <div className="link-block">
                <Link to="/">Gå tilbake</Link>
            </div>
        </div>
    )
}