import React from 'react';

export default function LyHealthDeclaration({ id }) {

    return (
        <div className=''>
            <h3 className='ly-page-header'>ORIENTERING OM HELSEERKLÆRINGEN</h3>
            <div className='ly-declaration'>
                <h4>Formålet med erklæringen</h4>
                <p>LY Forsikring ASA har inngått avtale med Crawford & Company (Norway) AS for utførelse av helsevurdering ved søknad om personforsikring. Crawford & Company er et selskap som tilbyr konsulenttjenester for forsikringsbransjen.
                    Crawford & Company trenger, på vegne av Ly Forsikring ASA, informasjon for å vurdere hvilken risiko du representerer i forhold til de generelle statistikker om helse, uførhet og død. Det er slike statistikker som ligger til grunn
                    for beregning av normale grunnpremier for en forsikring. Utfyllende og riktig informasjon om din helsetilstand på søknadstidspunktet er derfor av stor betydning for å fastsette riktig pris og riktige vilkår for din
                    forsikring. Ved søknad om personforsikring har man en opplysningsplikt fastsatt i Forskringsavtaleloven § 13-1 a. Det er derfor viktig at du fyller ut søknadsskjemaet og fullmaktserklæringen fullstendig og sannferdig.
                    Dette også for å unngå mulige tvister mellom deg og Ly Forsikring ASA i et eventuelt oppgjør.
                </p>

                <h4>Forsikringsselskapets bruk av dine svar</h4>
                <p>Helseopplysninger som gis til Ly Forsikring ASA og Crawford & Company blir behandlet konfidensielt. Selskapenes ansatte har taushetsplikt. Opplysningene du gir benyttes som grunnlag for selskapets helsebedømmelse.
                    Helsebedømmelsen består i å vurdere deg og din helsesituasjon i forhold til generell helsestatistikk, samt statistikk over personer med forskjellige typer forhøyet helserisiko. Hvis resultatet av helsebedømmelsen
                    medfører at forsikringen ikke kan tegnes (helt eller tidsbegrenset avslag) eller reservasjon (at forsikringen ikke gjelder for et spesielt helseforhold), vil du få skriftlig melding med begrunnelse om dette.
                </p>

                <h4>Hva skal du opplyse om</h4>
                <p>Du skal opplyse om alle forhold det spørres om, selv om disse er tilbake i tid og du nå er å anse som frisk. Du trenger ikke ta hensyn til forhold som ligger mer enn 10 år tilbake i tid,
                    dersom dette ikke har berørt deg siden. Du skal opplyse om både fysiske og psykiske forhold. Vanlig barnesykdommer (i barndommen), forkjølelse, influensa o.l. er det ikke nødvendig å gi opplysninger om.
                    Du skal ikke opplyse om presymptomatiske gentester (gentest der man ikke selv har noen symptomer på sykdommen/tilstanden) eller opplysninger om andre personer enn deg selv.
                </p>

                <h4>Behandling av erstatningskrav</h4>
                <p>Det trengs ikke ytterligere fullmakter for å innhente informasjon ved dødsfall. Ved krav om uføreerstatning kan det bli nødvendig å fylle
                    ut nye fullmakter for å få utbetaling fra forsikringen.
                </p>

                <h4>Unødvendig informasjon</h4>
                <p>Dersom de dokumentene som sendes til selskapet inneholder informasjon som er unødvendig for saksbehandlingen, skal det etter
                    retningslinjer gitt av Datatilsynet slettes eller sendes tilbake.
                </p>
            </div>
        </div>
    )
}